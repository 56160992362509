import React, { FC } from "react";
import styled, { css } from "styled-components";
import { inputStyle } from "./styles";
import { easeIn, easeOut } from "../styles/atoms";

import Select, { Option } from "rc-select";

interface SelectInput {
  options: Array<{
    value: string;
    name?: string;
    params?: Object;
    label?: () => JSX.Element;
  }>;
  value?: string;
  onChange?: (v: string, href: any) => void;
  onBlur?: any;
  error?: boolean;
  isFilter?: boolean;
  firstActiveValue?: any;
  disabled?: boolean;
}

const SelectInput: FC<SelectInput> = ({
  options,
  firstActiveValue,
  error,
  disabled,
  ...props
}) => {
  const handleClick = (value, params) => {
    if (value === "franchise") window.location = params.value;
  };

  return (
    <>
      <CustomSelect
        showSearch={false}
        optionLabelProp="children"
        onSelect={props.onBlur}
        error={error}
        defaultValue={props.isFilter && (firstActiveValue || "Все")}
        className={props.isFilter && "kabel"}
        dropdownStyle={{
          zIndex: 1001,
        }}
        disabled={disabled}
        firstActiveValue={firstActiveValue}
        {...props}
      >
        {options &&
          options.map(({ value, label, name, params }) => (
            <CustomOption
              key={value}
              value={value}
              onClick={() => handleClick(value, params)}
            >
              {label ? label() : name}
            </CustomOption>
          ))}
      </CustomSelect>
      <style jsx global>
        {`
          .rc-select-dropdown {
            box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            border: none;
            overflow: hidden;
          }

          li.rc-select-dropdown-menu-item {
            padding: 10px 15px;
            transition: 0.125s ease-in-out;
          }

          li.rc-select-dropdown-menu-item:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          }

          li.rc-select-dropdown-menu-item-active {
            background-color: rgba(253, 219, 3, 0.25);
            color: inherit;
          }

          .rc-select-dropdown-menu {
            max-height: 300px;
          }
        `}
      </style>
    </>
  );
};

interface CustomSelectProps {
  error?: boolean;
  isFilter?: boolean;
}

const FilterSelect = css`
  background: #fff;
  margin-right: 20px;
  color: #000;
`;

const CustomSelect = styled(Select)<CustomSelectProps>`
  width: 100%;

  .rc-select-selection {
    ${({ error }) => inputStyle({ error })};
    ${({ isFilter }) => isFilter && FilterSelect};
    padding: 10px 15px;
    padding-right: 34px;
    border: none;
    height: auto;
    min-height: 50px;
    display: flex;
    align-items: center;
  }

  .rc-select-selection,
  .rc-select-selection:hover {
    border-color: transparent;
    box-shadow: none;
  }

  .rc-select-selection__rendered {
    margin-left: 0;
    line-height: 1.3333;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .rc-select-selection-selected-value {
    position: static;
  }

  .rc-select-arrow {
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
  }

  .rc-select-arrow {
    height: 7px;
    width: 12px;
  }

  .rc-select-arrow-icon {
    border-width: 7px 6px 0 6px;
    border-color: #000 transparent transparent transparent;
    margin-left: 0;
    margin-top: 0;
    top: 0;
    left: 0;
    ${easeIn};
  }

  .rc-select-selection--single .rc-select-selection__rendered {
    height: auto;
  }

  &.rc-select-open {
    .rc-select-arrow-icon {
      transform: rotate(180deg);
      ${easeOut};
    }
  }

  &.rc-select-disabled .rc-select-selection {
    background: #e3e3e3;
  }
`;

const CustomOption: any = styled(Option)``;

export default SelectInput;
