import { css } from "styled-components";
import { RED } from "../styles/constants";
import { easeOut, easeIn } from "../styles/atoms";

export const inputStyle = ({ error }) => css`
  display: block;
  width: 100%;
  border: none;
  background: #f5f4f0;
  border-radius: 10px;
  height: 50px;
  &:after {
    position: absolute;
    bottom: 0;
    left: 6px;
    right: 6px;
    display: block;
    height: 2px;
    border-radius: 0 0 3px 3px;
    background-color: ${RED};
    ${error ? easeOut : easeIn};
    transform: ${error ? "scale3d(1,1,1)" : "scale3d(0, 1, 1)"};
    opacity: ${error ? "1" : "0.25"};
    content: "";
  }
`;
